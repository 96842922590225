import { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import BankTransferIndex from "secure/bank_transfer/Index";
import DeliveryComplaintIndex from "secure/delivery_complaint/Index";
import DeliveryComplaintShow from "secure/delivery_complaint/Show";
import IdentityVerificationIndex from "secure/identity_verification_request/Index";
import IdentityVerificationShow from "secure/identity_verification_request/Show";
import PurchaseIndex from "secure/purchase/Index";
import ReservationComplaintIndex from "secure/reservation_complaint/Index";
import ReservationComplaintShow from "secure/reservation_complaint/Show";
import SellerExaminationRequestIndex from "secure/seller_examination_request/Index";
import SellerExaminationRequestShow from "secure/seller_examination_request/Show";
import UserAccountIndex from "secure/user_account/Index";
import UserAccountShow from "secure/user_account/Show";
import ImpersonationIndex from 'secure/impersonation/Index';
import FinancialReportIndex from 'secure/financial_report/index';
import WithdrawalRequestIndex from "secure/withdrawal_request/Index";
import WithdrawalRequestShow from "./withdrawal_request/Show";

export default function SecureApp() {
  const [title, setTitle] = useState("");
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  return (
    <div id="SecureApp">
      <AppBar position="static" sx={{ backgroundColor: "#5CB531" }}>
        <Toolbar variant="dense">
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}
                      onClick={() => setDrawerIsOpen(true) }>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor={"left"} open={drawerIsOpen} onClose={() => setDrawerIsOpen(false) }>
        <Box sx={{ width: 250 }} role="presentation" onClick={() => setDrawerIsOpen(false) }>
          <List>
            <Link to="/secure/user_account/"><ListItem button><ListItemText primary={"ユーザー管理"} /></ListItem></Link>
            <Link to="/secure/identity_verification_request/"><ListItem button><ListItemText primary={"本人確認"} /></ListItem></Link>
            <Link to="/secure/bank_transfer/"><ListItem button><ListItemText primary={"銀行振込管理"} /></ListItem></Link>
            <Link to="/secure/seller_examination_request/"><ListItem button><ListItemText primary={"高額出品申請"} /></ListItem></Link>
            <Link to="/secure/purchase/"><ListItem button><ListItemText primary={"返金"} /></ListItem></Link>
            <Link to="/secure/withdrawal_request/"><ListItem button><ListItemText primary={"出金申請"} /></ListItem></Link>
            <Link to="/secure/financial_report/"><ListItem button><ListItemText primary={"売上報告"} /></ListItem></Link>
            <Link to="/secure/delivery_complaint/"><ListItem button><ListItemText primary={"提供内容に関する問題報告"} /></ListItem></Link>
            <Link to="/secure/reservation_complaint/"><ListItem button><ListItemText primary={"開催に関するお問い合わせ"} /></ListItem></Link>
            <Link to="/secure/impersonation/"><ListItem button><ListItemText primary={"なり代わりログイン"} /></ListItem></Link>
          </List>
        </Box>
      </Drawer>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginBottom: "10rem" }}>
            <Routes>
              <Route exact path="bank_transfer/" element={<BankTransferIndex setTitle={setTitle} />} />
              <Route exact path="delivery_complaint/" element={<DeliveryComplaintIndex setTitle={setTitle} />} />
              <Route exact path="delivery_complaint/:deliveryComplaintId" element={<DeliveryComplaintShow setTitle={setTitle} />} />
              <Route exact path="identity_verification_request/" element={<IdentityVerificationIndex setTitle={setTitle} />} />
              <Route exact path="identity_verification_request/:identityVerificationRequestId" element={<IdentityVerificationShow setTitle={setTitle} />} />
              <Route exact path="purchase/" element={<PurchaseIndex setTitle={setTitle} />} />
              <Route exact path="seller_examination_request/" element={<SellerExaminationRequestIndex setTitle={setTitle} />} />
              <Route exact path="seller_examination_request/:sellerExaminationRequestId" element={<SellerExaminationRequestShow setTitle={setTitle} />} />
              <Route exact path="reservation_complaint/" element={<ReservationComplaintIndex setTitle={setTitle} />} />
              <Route exact path="reservation_complaint/:reservationComplaintId" element={<ReservationComplaintShow setTitle={setTitle} />} />
              <Route exact path="user_account/" element={<UserAccountIndex setTitle={setTitle} />} />
              <Route exact path="user_account/:userAccountId" element={<UserAccountShow setTitle={setTitle} />} />
              <Route exact path="impersonation/" element={<ImpersonationIndex setTitle={setTitle} />} />
              <Route exact path="financial_report/" element={<FinancialReportIndex setTitle={setTitle} />} />
              <Route exact path="withdrawal_request/" element={<WithdrawalRequestIndex setTitle={setTitle} />} />
              <Route exact path="withdrawal_request/:withdrawalRequestId" element={<WithdrawalRequestShow setTitle={setTitle} />} />
            </Routes>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}